<template>
<div>
    <transition name="menu-popover">
      <div v-if="lightboxVideo" class="lightbox" @click="closeLightbox()">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="lightbox-vid" v-html="lightboxVideo">
            
        </div>
      </div>
    </transition>
    <div class="">
    <div class="hero-content">
   <div class="row">
     <div class="col-md-12">
        <div class="card">
            <!--
                        <video width="640" height="380" autoplay="autoplay">
                <source :src="selectedVid.link" type="video/mp4" />
            </video>
            -->
        <div v-if="selectedVid && selectedVid.poster" style="position: relative;">
            <img class="play" src="/play.svg" @click="showLightboxVideo(selectedVid.player)" />
            <img :src="selectedVid.poster" @click="showLightboxVideo(selectedVid.player)" style="width: 100%"/>
        </div>
        

        <!--
         <div class="embed-responsive embed-responsive-16by9">
            <iframe @click="showLightboxVideo(selectedVid.link)" class="embed-responsive-item" :src="selectedVid.link" ></iframe>
         </div>
         -->
         <div class="card-body" style="background: #f8fafc;">
            <h2 v-if="selectedVid && selectedVid.name" class="mt20" style="color: #5b6974; margin-top: 50px;">{{selectedVid.name}}</h2>
          <!--  <h5>Virtual Product/Application School</h5> -->
            <p v-if="selectedVid && selectedVid.desc">{{selectedVid.desc}}</p>

        <br/>

        <a href="/sales-and-training?cat=CET/Extension%20Overview%20Training" class="mb0 lh1 mlauto list-btn" style="display: flex; justify-content: center; align-items: center; padding: 1px 1px 1px 6px; max-width: 120px">View More <i data-v-35be5f95="" class="material-icons">keyboard_arrow_right</i></a>
         </div>
 
            
         </div>
      </div>

     
     </div>
   </div>
</div>
</div>

</template>
<script>
  export default {
    name: 'FeaturedVideos',
    //props: ['homeReports', 'loading'],
        data() {
        return {
        selectedVid: null,
        lightboxImage: "",
        lightboxVideo: "",
        selectedVid: null,
        vids: [
        {
                id: 11,
                name: "Spacesaver Extension Training",
                link: "",
                player: '"<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/u2hagnho0f/Order-Information---CET-Order-Overview.mp4?u=b4bimj\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Order Information - CET Order Overview&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\"></iframe></div>"',
                poster: "/vid-cards/order-information.jpg",
                desc:"CET users can learn the fundamental capabilities of Spacesaver's CET extension. Users will learn how to navigate and design a configurable project utilizing Spacesaver product."
            }, 

        {
                id: 15,
                name: "Spacesaver Extension Training",
                link: "",
                player: '"<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/v8kkawjmb4/Day-Use---1.mp4?u=b4bimj\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for \\\"Day Use - 1.1 - CET Product Overview\\\"\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>"',
                poster: "/vid-cards/DayUse1of2.jpg",
                desc:"CET users can learn the fundamental capabilities of Spacesaver's CET extension. Users will learn how to navigate and design a configurable project utilizing Spacesaver product."
            }, 
            {
                id: 15,
                name: "Beginner CET Training Day 1",
                link: "",
                player: '<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/8mdpmon92v/Beginner-CET-Training-Day-1.mp4?u=b4bimj\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Beginner CET Training Day 1&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\"></iframe></div>',
                poster: "/vid-cards/beginner-cet-training-day-1.jpg",
                desc:"Learn the basic functions of CET and take a simple project from start to finish using generic product."
            }, 
            {
                id: 15,
                name: "Searching Media on SpaceNET 3",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/09syu9jc7a/spacenet-search-walthrough.mp4?u=q2vy2h\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;spacenet-search-walthrough.mp4&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/media-search.jpg",
                desc:"Discover effective strategies for media search in SpaceNET 3 by referring to the Marketing Materials, Service & Installation, and Sales & Training sections"
            },  
            { 
                id: 0, 
                name: "Cybersecurity Briefing", 
                link: "https://spacesaver.widen.net/view/video/uvewxtrtvp/Vertical-Markets---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "https://spacenet3.spacesaver.com/vid-cards/cyber-briefing.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/jsuvpkg9zc/Cybersecurity-Briefing.mp4?u=b4bimj\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Cybersecurity Briefing&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Discover the best practices to avoid electronic payments fraud and phishing attacks at your organization."
            },
          
            { 
                id: 1, 
                name: "Vertical Markets", 
                link: "https://spacesaver.widen.net/view/video/uvewxtrtvp/Vertical-Markets---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/vertical-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/uvewxtrtvp/Vertical-Markets---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Vertical Markets - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Spacesaver offers storage solutions to a diverse set of industries. Learn more about each of Spacesaver’s vertical markets and how they serve the clients and partners within their respective industry."
            },
            { 
                id: 2, 
                name: "Marketing", 
                link: "https://spacesaver.widen.net/view/video/n4gfac4spw/Marketing---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/marketing-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/n4gfac4spw/Marketing---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Marketing - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Learn how Spacesaver’s Marketing Team – comprised of Marketing, Business Development, and Channel Development - works with ACs, partners, and stakeholders to execute Group initiatives, campaigns, and strategies."
            },
            { 
                id: 3, 
                name: "XTend/High-Bay", 
                link: "https://spacesaver.widen.net/view/video/edk3oidsrt/XTend-High-Bay---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/hi-bay-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/edk3oidsrt/XTend-High-Bay---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;XTend-High-Bay - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Learn how to reduce wasted aisle space and securely take your material storage to new heights with Spacesaver’s High-Bay and XTend mobile storage systems product and application training session." 
            },
            { 
                id: 4, 
                name: "Lockers", 
                link: "https://spacesaver.widen.net/view/video/1jgmoqaxtd/Lockers---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/lockers-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/1jgmoqaxtd/Lockers---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Lockers - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Designed to accommodate needs of multiple industries and their users, learn how Spacesaver’s storage locker product lineup can be customized and accessorized to fit your organization’s specific needs."

            },
            { 
                id: 5, 
                name: "Grow", 
                link: "https://spacesaver.widen.net/view/video/mjfu35sz56/Grow---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/grow-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/mjfu35sz56/Grow---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Grow - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Here is how Spacesaver’s GROW System provides the agricultural product solutions cultivators of all types of plants need to increase their yield and efficiency, while reducing product damage."
            },
            { 
                id: 6, 
                name: "Shelving", 
                link: "https://spacesaver.widen.net/view/video/2rpxp8dcnh/Shelving---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/shelving-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/2rpxp8dcnh/Shelving---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Shelving - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Learn how Spacesaver’s lineup of shelving solutions are designed to accommodate most workspaces and handle a variety of material sizes and weights, helping to address your organization’s storage needs."
            },
            { 
                id: 7, 
                name: "Museum", 
                link: "https://spacesaver.widen.net/view/video/dlotqnldpi/Museum---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/museum-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/dlotqnldpi/Museum---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Museum - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"From art racks to museum drawers and trays, learn more about Spacesaver’s museum storage products and accessories, designed to preserve and protect your collections while increasing visibility and accessibility."
            },
             
            {
                id: 10,
                name: "Modes of Operation",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/gk35dmro3y/Modes-of-Operation---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Modes of Operation - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/modes-card.jpg",
                desc:"Spacesaver’s product experts provide information on their mobile systems’ modes of operation – manual, mechanical assist, and powered – can provide the best storage solution for your space."
            },
             {
                id: 11,
                name: "ActivRAC",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/pmdhtiu1ub/ActivRAC---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;ActivRAC - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/activrac-card.jpg",
                desc:"Spacesaver’s ActivRAC® Mobilized Storage System product and application training provides an overview of each system, its components, and its capacity to store more by eliminating wasted aisle space."
            },
            {
                id: 12,
                name: "Wheelhouse & ExpressDEK",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/9xmmjscnil/Wheelhouse-XpressDek---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Wheelhouse-XpressDek - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/wheelhouse-card.jpg",
                desc:"Learn how Spacesaver’s Wheelhouse and XpressDek integrate with your existing space to provide the safe and secure foundation needed to support mobile storage systems in a myriad of industry applications."
            },
           
            { 
                id: 8, 
                name: "Weapons Racks", 
                link: "https://spacesaver.widen.net/view/video/aor7vyxgdd/Weapons-Racks---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/weapons-racks-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/aor7vyxgdd/Weapons-Racks---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Weapons Racks - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                desc:"Learn how Spacesaver’s Universal Weapons Rack (UWR®) storage system is designed to prevent ordnance from movement, vibration, shock, and abrasion, keeping your weapons and gear secure and ready for action." 
            },
            { 
                id: 9, 
                name: "Deployable Solutions", 
                link: "https://spacesaver.widen.net/view/video/tziotsr0u8/Deployable-Solutions---Virtual-Product---Application-School.mp4?u=sphzbh",
                poster: "/vid-cards/deployable-card.jpg",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/tziotsr0u8/Deployable-Solutions---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Deployable Solutions - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>", 
                desc:"See how Spacesaver’s deployable storage solutions help your military installation reduce loss, increase security, and remain ready for action while transporting arms and supporting equipment."
            },
            {
                id: 15,
                name: "High Density Storage Systems",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/gsfuwzmzjz/Carriage-and-Platforms---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Carriage and Platforms - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/high-density-card.jpg",
                desc:"Check out how Spacesaver’s High-Density Mobile Storage Systems can increase your facility’s usable space by up to 50%, while creating the optimum storage configuration for your materials."
            },
            {
                id: 13,
                name: "Rails & Floor",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/3ud66wxqia/Rails-and-Floor---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Rails and Floor - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/rails-floor-card.jpg",
                desc:"Learn how Spacesaver’s rail systems provide a solid foundation for your mobile storage system. No matter what flooring you have in your facility, Spacesaver has a safe and effective solution for your operation."
            },
            {
                id: 14,
                name: "Carriages & Platforms",
                link: "",
                player: "<div style=\"position:relative;width:100%;height:0;padding-bottom:56.25%;\"><iframe src=\"https://spacesaver.widen.net/view/video/gsfuwzmzjz/Carriage-and-Platforms---Virtual-Product---Application-School.mp4?u=sphzbh\" webkitallowfullscreen mozallowfullscreen allowfullscreen title=\"Video for &quot;Carriage and Platforms - Virtual Product - Application School&quot;\" frameborder=\"0\" allowtransparency=\"true\" scrolling=\"no\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" ></iframe></div>",
                poster: "/vid-cards/carriage-platform-card.jpg",
                desc:"See how Spacesaver’s innovative carriages and platforms are designed to provide your storage system the support it needs, while properly distributing weight load throughout operation."
            },  
                            
            
            ],
        };
    },
    methods: {
        showLightboxVideo(media) {
            console.log(media);
            this.lightboxVideo = media;
        },
        closeLightbox() {
        this.lightboxVideo = "";
        },
        getRandomItem() {
        // get random index value
        const randomIndex = Math.floor(Math.random() * this.vids.length);
        // get random item
        const item = this.vids[randomIndex];
        // this.selectedVid = item;
        this.selectedVid = this.vids[0];
        // return item;
        }
    },
    mounted() {  
    this.getRandomItem();
  },
}
</script>
<style scoped>
.card{
    background-color: #fff;
    padding: 20px;
    margin-bottom: 5px;
    border-top: 0;
    border-radius: 4px;
    background: #fff;
}
.announcement-link {
  margin-top: auto;
  background: #00b4b4;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}

.announcement-link:hover {
  background: #429da8;
}
.announcement-link:active {
  background: #429da8;
}
.play{
    position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 100px;
  top: 35%;
  cursor: pointer;
}
 
</style>